



























































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
@Component({})
export default class Name extends Vue {
  @Prop()
  private data: any;
  @Prop()
  private isDuibi: any;
  /**
   * @description 跳转智能标注
   */
  private jumpBiaozhu(message: any) {
    this.$router.push({
      path: "/main/research/eye/biaozhu",
      query: {
        id: message.eye_id,
        project_id: message.project_id,
      },
    });
  }
  /**
   * @description 跳转审核
   */
  private jumpShenhe(message: any) {
    this.$router.push({
      path: "/main/research/eye/biaozhu",
      query: {
        id: message.eye_id,
        project_id: message.project_id,
        type: "teacher",
      },
    });
  }
  /**
   * @description 跳转智能比对
   */
  private jumpBidui(message: any) {
    localStorage.removeItem("selectedEyes");
    localStorage.setItem("selectedEyes", JSON.stringify([message]));
    this.$router.push({
      path: "/main/research/eye/bidui-list",
    });
  }
  /**
   * @description 报告预览
   */
  private preview(msg: any) {
    this.$emit("preview", msg);
  }
  private changeSelect(message: any, value: any) {
    message.selected = !message.selected;
    this.$emit("trigger", message);
  }
  /**
   * @description 图片预览
   */
  private previewImg(src: any) {
    window.open(src);
  }
}
