












































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { listMixin } from "@/mixins/listMixin";
import { GetCenter, CreatorList } from "@/request/common";
import { ZuzhijiagouList } from "@/request/manage";
import {
  GetEyeList,
  PostSources,
  GetEyeCreator,
  GetEyeTags,
  GetEyePro,
} from "@/request/research";
import MainHeader from "@/components/main-header/main-header.vue";
import NavLeft from "@/components/nav-left/nav-left.vue";
import SearchZhuanbing from "@/components/search-zhuanbing.vue";
import SearchJigou from "@/components/search-jigou.vue";
import SearchCreator from "@/components/search-creator2.vue";
import SearchSource from "@/components/search-source.vue";
import PicList from "../components/picList.vue";
import TextList from "../components/textList.vue";
import UploadEye from "../components/upload-eye.vue";
@Component({
  components: {
    MainHeader,
    NavLeft,
    SearchZhuanbing,
    SearchJigou,
    SearchCreator,
    SearchSource,
    PicList,
    TextList,
    UploadEye,
  },
})
export default class Name extends mixins(listMixin) {
  private listType: any = "pic";
  private centers: any = [];
  private institutions: any = [];
  private creators: any[] = [];
  private data: any[] = [];
  private projects: any[] = [];
  private sources: any[] = [];
  private labels: any[] = [];
  private ifShowUpload: any = false;
  private exportEyes() {
    this.$router.push("/main/research/eye/export");
  }
  private goDictionary() {
    this.$router.push("/main/research/eye/dictionary");
  }
  private uploadChange() {
    this.search();
    this.ifShowUpload = false;
  }
  /**
   * @description 获得可选的标签列表
   */
  private fetchBiaoqianList() {
    return new Promise((resolve, reject) => {
      GetEyeTags(this)
        .then((data: any) => {
          this.labels = data;
          resolve(true);
        })
        .catch(() => {
          reject();
        });
    });
  }
  /**
   * @description 获得可选专病列表
   */
  private fetchCenters() {
    GetCenter(this).then((data: any) => {
      this.centers = data;
      this.centers.unshift({ project_id: "全部", 项目名称: "全部专病" });
      this.fetchHospitals();
    });
  }
  private remoteSourceMethod(e: any) {
    this.fetchSource(e);
  }
  private remoteCreatorMethod(e: any) {
    this.getCreatorsList(e);
  }
  /**
   * @description 获得来源
   */
  private fetchSource(val?: any) {
    const params: any = {
      keywords: val || "",
    };
    PostSources(this, params).then((data: any) => {
      this.sources = data;
      this.getCreatorsList();
      // 循环当前选项，如果不在列表中，需要插入进去
      if (this.filters.sources && this.filters.sources.length > 0) {
        this.filters.sources.forEach((ele: any) => {
          const index = this.sources.findIndex((val: any) => {
            return ele.source_id === val.source_id;
          });
          if (index == -1) {
            this.sources.unshift(ele);
          }
        });
      }
    });
  }
  /**
   * @description
   */
  private sourceChange(e: any) {
    this.filters.sources = e;
    // 重置创建人
    this.filters.user_ids = [];
    // 重置创建人选项
    this.getCreatorsList();
    // 搜索
    this.search();
  }

  /**
   * @description 获得可选机构列表
   */
  private fetchHospitals() {
    const params: any = {
      params: {
        project_id: this.filters.project_id,
      },
    };
    if (this.filters.project_id == "全部") {
      params.params.project_id = "";
    }
    ZuzhijiagouList(this, params).then((data: any) => {
      this.institutions = data;
      this.getCreatorsList();
    });
  }
  /**
   * @description 获得可选创建者列表
   */
  private getCreatorsList(e?: any) {
    const params: any = {
      ids: [],
      keywords: e || "",
    };
    if (this.filters.sources && this.filters.sources.length > 0) {
      this.filters.sources.forEach((ele: any) => {
        params.ids.push(ele.source_id);
      });
    }
    GetEyeCreator(this, params).then((data: any) => {
      this.creators = data;
      if (this.filters.user_ids && this.filters.user_ids.length > 0) {
        this.filters.user_ids.forEach((ele: any) => {
          const index = this.creators.findIndex((val: any) => {
            return ele.create_id === val.create_id;
          });
          if (index == -1) {
            this.creators.unshift(ele);
          }
        });
      }
    });
  }

  /**
   * @description 改变列表展示的形式
   */
  private changeListType(type: any) {
    this.listType = type;
  }
  /**
   * @description 选择专病改变
   */
  private centersChange(e: any) {
    this.filters.project_id = e;
    // 重置机构
    this.filters.institution_id = "";
    // 重置机构选项
    this.fetchHospitals();
    // 重置创建人
    this.filters.user_ids = [];
    // 重置创建人选项
    this.getCreatorsList();
    // 搜索
    this.search();
  }
  /**
   * @description 选择机构改变
   */
  private institutionChange(e: any) {
    this.filters.institution_id = e;
    // 重置创建人
    this.filters.user_ids = [];
    // 重置创建人选项
    this.getCreatorsList();
    // 搜索
    this.search();
  }
  /**
   * @description 选择创建人改变
   */
  private creatorChange(e: any) {
    this.filters.user_ids = e;
    // 搜索
    this.search();
  }
  /**
   * @description 改变排序顺序
   */
  private changeOrderValue() {
    this.filters.orderValue = this.filters.orderValue === 0 ? 1 : 0;
    this.search();
  }
  /**
   * @description 获得图片列表
   */
  getList() {
    const params: any = {
      // project_id: this.filters.project_id,
      dimension: this.filters["标注状态"],
      search: this.filters.search,
      // institution_id: this.filters.institution_id,
      creators: [],
      current_page: this.filters.current_page,
      per_page: this.filters.page_count,
      order_value: this.filters.orderValue,
      source_ids: [],
      tags: this.filters.tags,
    };
    if (!this.filters["标注状态"]) {
      params["dimension"] = "";
    }
    if (this.filters.sources && this.filters.sources.length > 0) {
      this.filters.sources.forEach((ele: any) => {
        params.source_ids.push(ele.source_id);
      });
    }
    if (this.filters.user_ids && this.filters.user_ids.length > 0) {
      this.filters.user_ids.forEach((ele: any) => {
        params.creators.push(ele.creator_id);
      });
    }
    localStorage.setItem("eyeFilter", JSON.stringify(this.filters));
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    GetEyeList(this, params)
      .then((data: any) => {
        loading.close();
        data.eyes.forEach((ele: any) => {
          ele.selected = false;
        });
        // this.$set(this, "data", data.eyes);
        this.data = data.eyes;
        this.filters.total_count = data.total_count;
        this.filters.total_page = data.total_page;
        if (this.data.length === 0) {
          this.ifShowEmpty = true;
        } else {
          this.ifShowEmpty = false;
        }
      })
      .catch(() => {
        loading.close();
      });
  }
  /**
   * @description 预览报告
   */
  private preview(d: any) {
    this.$router.push({
      path: "/main/research/eye/report",
      query: {
        id: d.eye_id,
      },
    });
  }
  private getPro() {
    GetEyePro(this).then((res: any) => {
      this.projects = res;
    });
  }
  /**
   * @description 初始化
   */
  mounted() {
    this.filters.project_id = "";
    this.filters.institution_id = "";
    this.filters.user_ids = [];
    this.filters.sources = [];
    this.filters.tags = [];
    this.filters["标注状态"] = "";
    this.filters.orderValue = 0;
    this.filters.orderField = "创建时间";
    this.filters.page_count = 12;
    const d = localStorage.getItem("eyeFilter") || "";
    if (d) {
      this.filters = JSON.parse(d);
    }
    // this.fetchCenters();
    this.fetchSource();
    this.fetchBiaoqianList();
    this.getList();
    this.getPro();
  }
}
