











































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { UploadFile } from "@/components/template-transfer-pc/template-transfer/request";
import {
  GetEyePro,
  GetEyePatient,
  GetEyeZhenci,
  UploadEye,
} from "@/request/research";
import AddPatient from "../../../storehouse/components/add-patient/add-patient.vue";
import { CreatePatient, AddZhenci } from "@/request/storehouse";
@Component({
  components: {
    AddPatient,
  },
})
export default class Name extends Vue {
  @Prop()
  private IfShow: any;
  @Watch("IfShow", { immediate: true })
  private IfShowChange() {
    this.ifVisible = this.IfShow;
    if (this.IfShow) {
      this.detail = {
        pro: {},
        zhenci: {},
        patient: {},
        l_eye_image: [],
        r_eye_image: [],
      };
      this.projects = [];
      this.nameOptions = [];
      this.zhencis = [{ zhenci_id: "新建诊次" }];
      this.refresh();
    }
  }
  private ifVisible: any = false;
  private ifShowAddPatient: any = false;
  private projects: any = [];
  private nameOptions: any = [];
  private zhencis: any = [{ zhenci_id: "新建诊次" }];
  private detail: any = {
    pro: {},
    zhenci: {},
    patient: {},
    l_eye_image: [],
    r_eye_image: [],
  };
  private proChange() {
    this.nameOptions = [];
    this.detail.patient = {};
    this.detail.zhenci = {};
    this.detail["姓名"] = "";
    if (this.detail["pro"].visit_type === "自定义访视计划") {
      this.zhencis = [];
    } else {
      this.zhencis = [
        {
          zhenci_id: "新建诊次",
        },
      ];
    }
    this.$forceUpdate();
  }
  private patientChange(e: any) {
    this.ifShowAddPatient = false;
    e.project_id = this.detail.pro.project_id;
    e.group_id = this.detail.pro.group_id;
    this.detail.patient = e;
    this.detail.patient.patient_id = "新增患者";
    this.detail["姓名"] = e["姓名"];
    this.detail.zhenci = {};
    if (this.detail["pro"].visit_type === "自定义访视计划") {
      this.zhencis = [];
    } else {
      this.zhencis = [
        {
          zhenci_id: "新建诊次",
        },
      ];
    }
    // this.zhencis = [
    //   {
    //     zhenci_id: "新建诊次",
    //   },
    // ];
  }
  private del(index: any, key: any) {
    this.detail[key].splice(index, 1);
  }
  private selectZhenci(item: any) {
    if (item.is_exist) {
      this.$confirm(
        "当前诊次已有目图数据，确定要重新上传该诊次目图数据吗？",
        "选择诊次",
        {
          customClass: "commonConfirm",
        }
      ).then(() => {
        this.detail.zhenci = item;
      });
    } else {
      this.detail.zhenci = item;
    }
  }
  private remoteMethod(val: any) {
    if (!this.detail.pro.project_id) {
      this.$message.warning("请选择专病数据库");
      return;
    }
    this.detail["姓名"] = val;
    const params = {
      source_id: this.detail.pro.project_id,
      search: val,
    };
    GetEyePatient(this, params).then((res: any) => {
      res.forEach((item: any) => {
        item.text = "";
        if (item["姓名"]) {
          item.text = item["姓名"] + "-";
        }
        if (item["性别"]) {
          item.text += item["性别"] + "-";
        }
        if (item["年龄"]) {
          item.text += item["年龄"] + "岁-";
        }
        if (item["身份证号"]) {
          item.text += item["身份证号"];
        }
        if (item["标签"]) {
          item.text += "(" + item["标签"] + ")";
        }
      });
      this.nameOptions = res;
    });
  }
  private nameChange(e: any) {
    this.detail.patient = e;
    this.detail["姓名"] = e["姓名"];
    this.detail.zhenci = {};
    if (this.detail.patient.patient_id) {
      this.getZhenci();
    } else {
      if (this.detail["pro"].visit_type === "自定义访视计划") {
        this.zhencis = [];
      } else {
        this.zhencis = [
          {
            zhenci_id: "新建诊次",
          },
        ];
      }
    }
  }
  private preview(url: any) {
    window.open(url);
  }
  private cancel() {
    this.$emit("cancel");
  }
  private sure() {
    if (!this.detail.pro.project_id) {
      this.$message.warning("请选择专病数据库");
      return;
    }
    if (!this.detail.patient.patient_id) {
      this.$message.warning("请选择患者");
      return;
    }
    if (!this.detail.zhenci.zhenci_id) {
      this.$message.warning("请选择诊次");
      return;
    }
    if (
      this.detail.l_eye_image.length == 0 &&
      this.detail.r_eye_image.length == 0
    ) {
      this.$message.warning("请上传目图");
      return;
    }
    if (
      this.detail.patient.patient_id != "新增患者" &&
      this.detail.zhenci.zhenci_id != "新建诊次"
    ) {
      this.uploadSave();
    } else if (this.detail.patient.patient_id == "新增患者") {
      this.addPatient().then((patient: any) => {
        this.detail.patient = patient;
        this.addZhenci().then((zhenci: any) => {
          this.detail.zhenci = zhenci;
          this.uploadSave();
        });
      });
    } else {
      this.addZhenci().then((zhenci: any) => {
        this.detail.zhenci = zhenci;
        this.uploadSave();
      });
    }
  }
  private upload(file: any, key: any) {
    const fileObj = file.file;
    // FormData 对象
    const form = new FormData();
    // 文件对象
    form.append("type", "file");
    form.append("file", fileObj);
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    UploadFile(this, form, loading)
      .then((res: any) => {
        loading.close();
        this.detail[key].push(res.full_file_url);
      })
      .catch(() => {
        loading.close();
      });
  }
  private addPatient() {
    return new Promise((resolve, reject) => {
      let obj: any = JSON.parse(JSON.stringify(this.detail.patient));
      delete obj.patient_id;
      CreatePatient(this, obj).then((data: any) => {
        resolve(data);
      });
    });
  }
  private addZhenci() {
    return new Promise((resolve, reject) => {
      const params: any = {
        project_id: this.detail.pro.project_id,
        group_id: this.detail.pro.group_id,
        patient_id: this.detail.patient.patient_id,
        type: "0",
      };
      AddZhenci(this, params).then((data: any) => {
        resolve(data);
      });
    });
  }
  private uploadSave() {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      source: this.detail.pro["项目名称"],
      project_id: this.detail.pro.project_id,
      source_type: 0,
      patient_id: this.detail.patient.patient_id,
      zhenci_id: this.detail.zhenci.zhenci_id,
      l_eye_image: this.detail.l_eye_image,
      r_eye_image: this.detail.r_eye_image,
    };
    UploadEye(this, params, loading).then((res: any) => {
      this.$message.success("上传成功");
      this.$emit("change");
    });
  }
  private getPro() {
    GetEyePro(this).then((res: any) => {
      this.projects = res;
    });
  }
  private getZhenci() {
    if (this.detail["pro"].visit_type === "自定义访视计划") {
      this.zhencis = [];
    } else {
      this.zhencis = [
        {
          zhenci_id: "新建诊次",
        },
      ];
    }
    if (!this.detail.pro.project_id) {
      this.$message.warning("请选择专病数据库");
      return;
    }
    if (!this.detail.patient.patient_id) {
      this.$message.warning("请选择患者");
      return;
    }
    const params: any = {
      project_id: this.detail.pro.project_id,
      patient_id: this.detail.patient.patient_id,
    };
    GetEyeZhenci(this, params).then((res: any) => {
      this.zhencis = this.zhencis.concat(res);
    });
  }
  private refresh() {
    this.getPro();
  }
}
