























































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
@Component({})
export default class TongueTextList extends Vue {
  @Prop()
  private data: any;
  @Prop()
  private isDuibi: any;
  @Prop()
  private filters: any;
  /**
   * @description 跳转智能标注
   */
  private jumpBiaozhu(message: any) {
    this.$router.push({
      path: "/main/research/eye/biaozhu",
      query: {
        id: message.eye_id,
        project_id: message.project_id,
      },
    });
  }
  private jumpShenhe(message: any) {
    this.$router.push({
      path: "/main/research/eye/biaozhu",
      query: {
        id: message.eye_id,
        project_id: message.project_id,
        type: "teacher",
      },
    });
  }
  /**
   * @description 跳转智能比对
   */
  private jumpBidui(message: any) {
    localStorage.removeItem("selectedTongues");
    localStorage.setItem("selectedTongues", JSON.stringify([message]));
    this.$router.push({
      path: "/main/research/eye/bidui-list",
    });
  }
  /**
   * @description 切换选中的舌图
   */
  private handleCheckedMessageChange(event: any, message: any) {
    message.selected = !message.selected;
    this.$emit("trigger", message);
  }
  private preview(msg: any) {
    this.$emit("preview", msg);
  }
}
